import { PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import toast from "react-hot-toast";
import { auth } from "./firebase";
import { CONSUMED_FE_URL } from "../config";

export const verifyOTP = (otp, mode = "signup") => {
  // console.log("Passed otp", otp);
  // console.log("Ref otp:", window.confirmationResult);

  const confirmationResultString = sessionStorage.getItem("otp");
  if (!confirmationResultString) {
    toast.error("No confirmation result found. Please request OTP again.");
    return;
  }
  const confirmationResult = JSON.parse(confirmationResultString);
  // console.log(confirmationResult);

  const credential = PhoneAuthProvider.credential(
    confirmationResult.verificationId,
    otp
  );
  signInWithCredential(auth, credential)
    .then((result) => {
      // User signed in successfully
      // console.log("User signed in:", result.user);

      toast.success("OTP verified successfully");
      if (mode === "password") {
        window.location.href = `${CONSUMED_FE_URL}/reset-password`;
      } else {
        window.location.href = `${CONSUMED_FE_URL}/signin`;
      }
      // Navigate to the dashboard or perform any post-login action
      // Example: window.location.href = "/dashboard";
    })
    .catch((error) => {
      // console.error("Error verifying OTP:", error);
      toast.error("Invalid OTP. Please try again.");
      throw error;
    });
};

//   confirmationResult
//     .confirm(otp) // The OTP entered by the user
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     .then((result: any) => {
//       // OTP is verified successfully
//       const user = result.user;
//       console.log("Phone number verified successfully:", user);
//       toast.success("Phone number verified successfully!");
//       // window.location.href = "http://localhost:5173/reset-password";
//     })
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     .catch((error: any) => {
//       // Error verifying OTP
//       console.error("Error verifying OTP:", error);
//       toast.error("Invalid OTP");
//     });
// };
