import { useState } from "react";
import { useHistory } from "react-router-dom";
import { createUser, sendOtpSignup, verifyOtpPass } from "../../services/auth";
import toast from "react-hot-toast";
// import { useAuth } from "../../utils/AuthContext";

function useOtp() {
  const navigate = useHistory();

  const userData = sessionStorage.getItem("userData") || "{}";
  const cachedData = JSON.parse(userData);

  // States to track loading state
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerifyOtpPass, setIsVerifyOtpPass] = useState(false);

  // Function to send OTP
  const sendOtpFn = async ({ data, mobile }) => {
    setIsSendingOtp(true);
    try {
      const response = await sendOtpSignup(data, mobile);
      sessionStorage.setItem("userData", JSON.stringify(response));
      // toast.success("OTP Sent Successfully!");
    } catch (error) {
      toast.error("Some Error Occurred!");
    } finally {
      setIsSendingOtp(false);
    }
  };

  // Function to verify OTP and create user
  const verifyOtpFn = async () => {
    if (!cachedData) {
      toast.error("No user data found. Please start over.");
      return;
    }

    setIsVerifying(true);
    try {
      await createUser(cachedData);
      navigate.push("/signin");
      toast.success("Account has been created Successfully!");
      sessionStorage.clear();
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : "Some Error Occurred!"
      );
    } finally {
      setIsVerifying(false);
    }
  };

  // Function to verify OTP for password reset
  const verifyOtpPassFn = async (otp) => {
    setIsVerifyOtpPass(true);
    try {
      await verifyOtpPass(otp);
      //   setIsReset(true);
      sessionStorage.setItem("isResetting", "true");
      toast.success("Verifying...");
    } catch (error) {
      toast.error(
        error instanceof Error ? error.message : "Some Error Occurred!"
      );
    } finally {
      setIsVerifyOtpPass(false);
    }
  };

  return {
    sendOtpFn,
    isSendingOtp,

    verifyOtpFn,
    isVerifying,

    verifyOtpPassFn,
    isVerifyOtpPass,
  };
}

export default useOtp;
