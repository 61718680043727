import Layout from "./Layout";
import { useEffect, useState } from "react";
import React from "react";
import { getCategory, getFilterdProducts } from "./apiCore";
import CheckBox from "./CheckBox";
import { FixedPrice } from "./FixedPrice";
import RadioBox from "./RadioBox";
import Card from "./Card";
import Search from "./Search";
const Shop = (props) => {
  const [categories, setCategories] = useState([]);
  const [myFilters, setMyFilters] = useState({
    filter: { category: [], price: [] },
  });
  const [error, setError] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(6);
  const [size, setSize] = useState(0);
  const [filteredResults, setFilteredResults] = useState([]);
  const init = () => {
    getCategory().then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setCategories(data);
      }
    });
  };
  useEffect(() => {
    init();
    console.log(props);
    loadFilterResults(myFilters.filter);
  }, []);
  const handelFiler = (filters, filterBy) => {
    const newFilters = { ...myFilters };
    newFilters.filter[filterBy] = filters;
    if (filterBy === "price") {
      let priceValues = handelPrice(filters);
      newFilters.filter[filterBy] = priceValues;
    }
    loadFilterResults(myFilters.filter);
    setMyFilters(newFilters);
  };
  const loadFilterResults = (filters) => {
    getFilterdProducts(skip, limit, filters).then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setFilteredResults(data.data);
        setSize(data.size);
      }
    });
  };
  const handelPrice = (value) => {
    const data = FixedPrice;
    let array = [];
    for (let key in data) {
      if (data[key]._id === parseInt(value)) array = data[key].array;
    }
    return array;
  };
  const loadMore = () => {
    let toSkip = skip + limit;
    getFilterdProducts(toSkip, limit, myFilters.filter).then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setFilteredResults([...filteredResults, ...data.data]);
        setSize(data.size);
        setSkip(0);
      }
    });
  };
  const searchedProducts = () => {
    if (props.location.state !== undefined) {
      return (
        <div className="row col-12 col-lg-3 ml-3 ">
          {props.location.state.products.map((p, i) => (
            <Card key={i} product={p} />
          ))}
        </div>
      );
    }
  };
  const loadMoreBtn = () => {
    return (
      size > 0 &&
      size >= limit && (
        <button className="btn btn-outline-warning mb-5" onClick={loadMore}>
          Load More
        </button>
      )
    );
  };
  const createUi = () => {
    return (
      <div className="row">
        <Search></Search>
        <div className="col-12 col-lg-3  mt-2">
          <h4>Filterd By Category</h4>
          <ul>
            <CheckBox
              categories={categories}
              handelFiler={(filter) => handelFiler(filter, "category")}
            />
          </ul>
          <h4>Filterd By Price</h4>
          <ul>
            <RadioBox
              price={FixedPrice}
              handelFiler={(filter) => handelFiler(filter, "price")}
            />
          </ul>
        </div>
        <div className="col-12 col-lg-9 ">
          <h2 className="text-3xl font-semibold my-6"> Products</h2>
          <div className="row">
            {filteredResults.map((product, i) => {
              return (
                <div key={i} className="mb-3 col-12 col-lg-6">
                  <Card product={product} location={"/shop"} />
                </div>
              );
            })}
            <div>
              <hr></hr>
              {loadMoreBtn()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Layout
      title="WELCOME TO ZAGSYSTEMS"
      discreption="IOT, EMBEDED SYSTEMS AND SOFTWARE SOLUTIONS"
      className="container-fluid"
    >
      <div className="row">{searchedProducts()}</div>
      {createUi()}
      {console.log("property_id", props)}
    </Layout>
  );
};
export default Shop;
