import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { getCategory } from "./apiAdmin";
// import { Link } from "react-router-dom";
import AdminLinks from "./../user/AdminLinks";
import { useAuth } from "../utils/AuthContext";
import toast from "react-hot-toast";
import { compressImage } from "./utils/compressImage";
import { addProduct } from "../services/admin/admin";
const AddProduct = () => {
  const { user } = useAuth();
  const [selectedImages, setSelectedImages] = useState([]);
  // const [formData] = useState(new FormData());

  const [values, setValues] = useState({
    name: "",
    description: "",
    price: "",
    category: "",
    categories: "",
    Quentity: "",
    photo: "",
    photos: "",
    shipping: "",
    error: "",
    sold: 0,
    loading: false,
    createdProduct: "",
    redirectToProfile: "",
    formData: "",
  });
  const {
    name,
    description,
    price,
    category,
    error,
    categories,
    Quentity,
    shipping,
    sold,
    // loading,
    createdProduct,
    // redirectToProfile,
    formData,
  } = values;
  const init = () => {
    getCategory().then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error });
      } else {
        setValues({ ...values, categories: data, formData: new FormData() });
      }
    });
  };
  useEffect(() => {
    init();
  }, []);
  const onChangeHandler = (name) => (event) => {
    if (name === "photo") {
      // Single image upload (only first selected file)
      const file = event.target.files[0];
      const preview = URL.createObjectURL(file);
      setSelectedImages([preview]);

      formData.set(name, file);
      setValues({ ...values, [name]: file });
    } else if (name === "photos") {
      // Multiple images upload
      const files = Array.from(event.target.files);
      files.forEach((file) => {
        const preview = URL.createObjectURL(file);
        setSelectedImages([...selectedImages, preview]);
      });

      files.forEach((file) => formData.append(name, file)); // Append each file
      setValues((prev) => ({
        ...prev,
        photos: [...(prev.photos || []), ...files],
      }));
    } else {
      formData.set(name, event.target.value);
      setValues({ ...values, [name]: event.target.value });
    }
  };

  // const onChangeHandler = (name) => async (event) => {
  //   try {
  //     if (name === "photo") {
  //       // Single image upload with compression
  //       const file = event.target.files[0];
  //       if (file) {
  //         const compressedFile = await compressImage(file);

  //         // Create preview
  //         const preview = URL.createObjectURL(compressedFile);
  //         setSelectedImages([preview]);

  //         // Update form data and state
  //         formData.set(name, compressedFile);
  //         setValues((prev) => ({
  //           ...prev,
  //           [name]: compressedFile,
  //         }));
  //       }
  //     } else if (name === "photos") {
  //       // Multiple images upload with compression
  //       const files = Array.from(event.target.files);

  //       // Compress multiple files
  //       const compressedFiles = await Promise.all(
  //         files.map(async (file) => {
  //           if (!file.type.startsWith("image/")) {
  //             toast.warn(`Skipping non-image file: ${file.name}`);
  //             return null;
  //           }
  //           return await compressImage(file);
  //         })
  //       );

  //       // Filter valid compressed files
  //       const validCompressedFiles = compressedFiles.filter(
  //         (file) => file !== null
  //       );

  //       // Create previews
  //       const newPreviews = validCompressedFiles.map((file) =>
  //         URL.createObjectURL(file)
  //       );

  //       // Clear previous files and append new compressed files
  //       formData.delete(name);
  //       validCompressedFiles.forEach((file) => {
  //         formData.append(name, file);
  //       });

  //       // Update state and previews
  //       setSelectedImages((prev) => [...prev, ...newPreviews]);
  //       setValues((prev) => ({
  //         ...prev,
  //         [name]: validCompressedFiles,
  //       }));
  //     } else {
  //       // Handle non-file inputs
  //       const value = event.target.value;
  //       formData.set(name, value);
  //       setValues((prev) => ({
  //         ...prev,
  //         [name]: value,
  //       }));
  //     }
  //   } catch (error) {
  //     console.error(`Error processing ${name}:`, error);
  //     toast.error(`Failed to process ${name}. Please try again.`);
  //   }
  // };

  const removeImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const onClickHandler = async (e) => {
    e.preventDefault();
    setValues((prevValues) => ({ ...prevValues, error: "", loading: true }));
    console.log("Before compression:", values.photo.size);

    const MAX_SIZE = 100 * 1024;
    if (!values.photo) {
      toast.error("Please select an image.");
      setValues((prevValues) => ({ ...prevValues, loading: false }));
      return;
    }

    console.log("Before compression:", values.photo.size);

    try {
      const compressedFile = await compressImage(values.photo, 0.6);
      console.log("Compressed File Size:", compressedFile.size); // Log compressed size
      if (compressedFile.size > MAX_SIZE) {
        toast.error(
          "Compressed image is still too large! Please upload a smaller file."
        );
        setValues((prevValues) => ({ ...prevValues, loading: false }));
        return;
      }
      setValues((prevValues) => ({ ...prevValues, photo: compressedFile }));
      console.log(compressedFile);
      await addProduct(user?._id, {
        ...values,
        photo: compressedFile,
      });
      setValues((prevValues) => ({ ...prevValues, loading: false }));

      toast.success("Product added successfully!");
    } catch (error) {
      console.log(error);
      toast.error("Image compression failed! please upload a smaller image");
      setValues((prev) => ({ ...prev, loading: false }));
    }

    // console.log("After compression:", values.photo.size);

    // addProduct(user?._id, token, formData).then((data) => {
    //   if (data.error) {
    //     setValues({ ...values, error: data.error });
    //     return data.error;
    //   } else {
    //     setValues({
    //       ...values,
    //       name: "",
    //       description: "",
    //       price: "",
    //       category: "",
    //       Quentity: "",
    //       photo: "",
    //       shipping: "",
    //       error: "",
    //       sold: 0,
    //       loading: false,
    //       createdProduct: data.name,
    //     });
    // }
    // });
  };
  const showError = () => (
    <div
      className="alert alert-danger"
      style={{ display: error ? "" : "none" }}
    >
      {error}
    </div>
  );

  const showSuccess = () => (
    <div
      className="alert alert-info"
      style={{ display: createdProduct ? "" : "none" }}
    >
      <h2>{`${createdProduct}`} is created!</h2>
    </div>
  );

  // const showLoading = () =>
  //   loading && (
  //     <div className="alert alert-success">
  //       <h2>Loading...</h2>
  //     </div>
  //   );
  /*const goBack = () => {
    if(success) 
    {
        return(
            <div className = "mt-5">
                <Link to = '/admin/dashboard' className ="text-warning" >Back to Dashboard</Link>
            </div>
        );
    }
}*/
  const creatUi = () => {
    return (
      <form className="mb-3" onSubmit={onClickHandler}>
        <h3>Upload Photo</h3>
        <div encType="multipart/form-data">
          <label className="btn btn-secondary">
            <input
              type="file"
              accept="image/*"
              name="photo"
              multiple
              onChange={onChangeHandler("photo")}
              style={{ overflow: "hidden" }}
            />
          </label>
        </div>

        <br />
        <h3>Upload Additional Photos</h3>
        <div>
          <label className="btn btn-secondary">
            <input
              type="file"
              accept="image/*"
              name="photo"
              multiple
              onChange={onChangeHandler("photos")}
            />
          </label>
        </div>
        <div
          className="image-preview"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {selectedImages.map((preview, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                margin: "5px",
                width: "100px",
                height: "100px",
              }}
            >
              <img
                src={preview}
                alt={`preview ${index}`}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
              <button
                type="button"
                onClick={() => removeImage(index)}
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  background: "red",
                  color: "white",
                  border: "none",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              >
                X
              </button>
            </div>
          ))}
        </div>

        {selectedImages.length > 0 && (
          <div>
            <strong>
              {selectedImages.length} Image
              {selectedImages.length !== 1 ? "s" : ""} Selected
            </strong>
          </div>
        )}

        <div className="form-group">
          <label className="text-muted ">Name</label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={onChangeHandler("name")}
          ></input>
        </div>
        <br></br>
        <div className="form-group">
          <label className="text-muted ">Descreption</label>
          <textarea
            type="textarea"
            className="form-control"
            value={description}
            onChange={onChangeHandler("description")}
          ></textarea>
        </div>
        <br></br>
        <div className="form-group">
          <label className="text-muted ">Price</label>
          <input
            type="price"
            className="form-control"
            value={price}
            onChange={onChangeHandler("price")}
          ></input>
        </div>
        <br></br>
        <div className="form-group">
          <label className="text-muted ">Category</label>
          <select
            className="form-control"
            value={category}
            onChange={onChangeHandler("category")}
          >
            <option>Please select the category</option>
            {categories &&
              categories.map((c, i) => {
                return (
                  <option key={i} value={c._id}>
                    {c.name}
                  </option>
                );
              })}
          </select>
        </div>
        <br></br>
        <div className="form-group">
          <label className="text-muted ">Shipping</label>
          <select
            className="form-control"
            value={shipping}
            onChange={onChangeHandler("shipping")}
          >
            <option>Please select the shipping</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
        <br></br>
        <div className="form-group">
          <label className="text-muted ">Quantity</label>
          <input
            type="Number"
            className="form-control"
            value={Quentity}
            onChange={onChangeHandler("Quentity")}
          ></input>
        </div>
        <br></br>
        <div className="form-group">
          <label className="text-muted ">Sold</label>
          <input
            type="Number"
            className="form-control"
            value={sold}
            onChange={onChangeHandler("sold")}
          ></input>
        </div>
        <br></br>
        <div className="form-group">
          <button className="btn btn-primary" disabled={values.loading}>
            {values.loading ? "Loading..." : "Submit"}
          </button>
        </div>
      </form>
    );
  };
  return (
    <Layout
      title="Add New Product "
      discreption="Are you ready to add new product ? "
      className="container-fluid"
    >
      <div className="row">
        <div className="col-5 col-lg-3 ">
          <AdminLinks></AdminLinks>
        </div>
        <div className="col-7">
          {showError()}
          {showSuccess()}
          {creatUi()}
        </div>
      </div>
    </Layout>
  );
};
export default AddProduct;
