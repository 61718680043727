import { useState } from "react";
import { createOrder } from "../../services/order";
import { useAuth } from "../AuthContext";
import toast from "react-hot-toast";

function useOrder() {
  const [loading, setLoading] = useState(false);
  //   const [err, setErr] = useState(false)
  const { user } = useAuth();

  const createOrderFn = async (order) => {
    try {
      setLoading(true);
      const orderData = await createOrder(user._id, order);
      toast.success("Order has been created");
    } catch (err) {
      toast.error(err.message ? err.message : err);
    }
  };

  return {
    loading,
    createOrderFn,
  };
}

export default useOrder;
