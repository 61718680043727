import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import useLogin from "./useLogin";
import { PhoneInput } from "react-international-phone";
import { loginValidation } from "../register/validation";
import { useState } from "react";

const formData = [
  {
    id: 1,
    placeholder: "Phone",
    type: "text",
    name: "phone",
  },
  {
    id: 2,
    placeholder: "Password",
    type: "password",
    name: "password",
  },
];

function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(loginValidation),
    mode: "onBlur",
  });

  const { loginUserFn, isLogging, isErrorLogging } = useLogin();

  const onSubmit = (formData) => {
    loginUserFn(formData);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-full h-full gap-8">
      <form
        className="flex flex-col gap-6 items-center justify-center w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="md:text-xl">Sign in to your account</p>

        {formData.map((item) => (
          <div key={item.id} className="w-full">
            <div className="w-full ">
              {item.id === 1 ? (
                <>
                  <label
                    className="cursor-pointer text-xl mb-2"
                    htmlFor={item.id.toString()}
                  >
                    {item.name}
                  </label>
                  <Controller
                    id={item.id.toString()}
                    name={item.name}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <PhoneInput
                        value={value}
                        onChange={(val, country) => {
                          const dialCode = country?.country.dialCode || ""; // Get the dial code dynamically
                          let cleanNumber = val.replace(/\D/g, ""); // Remove non-numeric characters

                          // Case 1: If the number doesn't start with the dial code, add it
                          if (!cleanNumber.startsWith(dialCode)) {
                            cleanNumber = `${dialCode}${cleanNumber}`;
                          }

                          // Case 2: If the number starts with the dial code and a leading 0, remove the 0
                          if (cleanNumber.startsWith(`${dialCode}0`)) {
                            cleanNumber = `+${dialCode}${cleanNumber.slice(
                              dialCode.length
                            )}`;
                          }

                          // Case 3: Handle numbers starting with the dialCode without a 0 in between
                          if (cleanNumber.startsWith(dialCode)) {
                            cleanNumber = `+${cleanNumber}`; // Format as +20 or similar
                          }

                          // Finally, update the value with the correctly formatted number
                          onChange(cleanNumber);
                        }}
                        placeholder="xxxx-xxxx xxxx"
                        defaultCountry="eg"
                        charAfterDialCode=""
                        className="bg-transparent text-2xl text-blue-600 border-2 border-[#0000006a] rounded-[10px] h-12 flex items-center justify-center px-4 w-full outline-none"
                        inputClassName="w-full h-full "
                        inputStyle={{
                          background: "transparent",
                          border: "none",
                          color: "#000",
                          fontSize: "1.5rem",
                          height: "100%",
                        }}
                        countrySelectorStyleProps={{
                          buttonStyle: {
                            background: "transparent",
                            border: "none",
                            borderRight: "2px solid #0000006a",
                            padding: "0 1rem",
                            color: "white",
                            width: "4rem",
                          },
                        }}
                      />
                    )}
                  />
                </>
              ) : (
                <div className="relative">
                  <label
                    className="cursor-pointer text-xl mb-2"
                    htmlFor={item.id.toString()}
                  >
                    {item.name}
                  </label>
                  <input
                    id={item.id.toString()}
                    className="bg-transparent border-2 border-[#0000006a] rounded-[10px] py-3 px-4 w-full outline-none"
                    type={
                      item.type === "password" && showPassword
                        ? "text"
                        : item.type
                    }
                    placeholder={item.placeholder}
                    {...register(item.name, { required: true })}
                  />

                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute top-1/2 right-4"
                  >
                    {showPassword && item.type === "password" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="m10.12 10.827l4.026 4.027a.5.5 0 0 0 .708-.708l-13-13a.5.5 0 1 0-.708.708l3.23 3.23A6 6 0 0 0 3.2 6.182a6.7 6.7 0 0 0-1.117 1.982c-.021.061-.047.145-.047.145l-.018.062s-.076.497.355.611a.5.5 0 0 0 .611-.355l.001-.003l.008-.025l.035-.109a5.7 5.7 0 0 1 .945-1.674a5 5 0 0 1 1.124-1.014L6.675 7.38a2.5 2.5 0 1 0 3.446 3.446m-.74-.74A1.5 1.5 0 1 1 7.413 8.12zM6.32 4.2l.854.854Q7.564 5 8 5c2.044 0 3.286.912 4.028 1.817a5.7 5.7 0 0 1 .945 1.674q.025.073.035.109l.008.025v.003l.001.001a.5.5 0 0 0 .966-.257v-.003l-.001-.004l-.004-.013a2 2 0 0 0-.06-.187a6.7 6.7 0 0 0-1.117-1.982C11.905 5.089 10.396 4 8.002 4c-.618 0-1.177.072-1.681.199"
                        />
                      </svg>
                    ) : !showPassword && item.type === "password" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="currentColor"
                          d="M8 6.003a2.667 2.667 0 1 1 0 5.334a2.667 2.667 0 0 1 0-5.334m0 1a1.667 1.667 0 1 0 0 3.334a1.667 1.667 0 0 0 0-3.334m0-3.336c3.076 0 5.73 2.1 6.467 5.043a.5.5 0 1 1-.97.242a5.67 5.67 0 0 0-10.995.004a.5.5 0 0 1-.97-.243A6.67 6.67 0 0 1 8 3.667"
                        />
                      </svg>
                    ) : null}
                  </button>
                </div>
              )}
            </div>
            {errors[item.name] && (
              <span className="text-red-600 text-sm text-left block">
                {item.name === "phone"
                  ? "please enter your phone number"
                  : "please enter your password"}
              </span>
            )}
          </div>
        ))}

        <button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          className="bg-[#da0e1a] rounded-lg w-full py-2 md:py-4 font-medium text-sm md:text-xl cursor-pointer transition-colors hover:bg-[#c40d17] mb-4 text-white"
        >
          {isLogging ? "loading..." : "Login"}
        </button>
      </form>
      {isErrorLogging && (
        <p className="text-red-600 text-sm">Internal Server Error</p>
      )}

      <div className="flex items-center gap-2 text-xl">
        <p>Don't have an account, please</p>
        <Link to="/signup" className="underline hover:text-[#c40d17]">
          Signup
        </Link>
      </div>
    </div>
  );
}

export default Login;
