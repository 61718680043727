import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import "bootstrap/dist/js/bootstrap.js";
import { AuthProvider } from "./utils/AuthContext";
import { CartProvider } from "./utils/CartContext";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <CartProvider>
        <App />
      </CartProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
