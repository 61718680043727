import toast from "react-hot-toast";
import { auth, RecaptchaVerifier, signInWithPhoneNumber } from "./firebase";
import { CONSUMED_FE_URL } from "../config";

export const sendOTP = (phoneNumber, mode = "signup") => {
  //   const navigate = useNavigate();
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container", // ID of the container
      {
        size: "invisible", // Makes reCAPTCHA invisible
        callback: () => {
          console.log("reCAPTCHA verified");
          // window.location.href = "localhost:5173/verify-otp?mode=password";
        },
      }
    );
  }

  const recaptchaVerifier = window.recaptchaVerifier;
  recaptchaVerifier
    .render()
    .then(() => {
      signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
        .then((confirmationResult) => {
          // console.log("OTP sent");
          // window.confirmationResult = confirmationResult;
          // console.log(confirmationResult);

          sessionStorage.setItem("otp", JSON.stringify(confirmationResult));
          if (mode === "password") {
            window.location.href = `${CONSUMED_FE_URL}/verify-otp?mode=password`;
          } else {
            window.location.href = `${CONSUMED_FE_URL}/verify-otp`;
          }

          toast.success("OTP sent");
        })

        .catch((error) => {
          // console.log("Error sending OTP:", error);
          // toast.error("Error sending OTP");
          throw error;
        });
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((error) => {
      console.log("Error rendering reCAPTCHA:", error);
      toast.error("Error rendering reCAPTCHA");
    });
};

//   signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
//     .then((confirmationResult) => {
//       // OTP sent
//       console.log("OTP sent");
//       toast.success("OTP sent");
//       window.confirmationResult = confirmationResult;
//     })
//     .catch((error) => {
//       console.error("Error sending OTP:", error);
//     });
// };

// Call this function with the phone number
