import Cookies from "js-cookie";
import api from "../utils/api";

const addProduct = async (userId, product) => {
  try {
    if (!userId || !product) throw new Error("Missing userId or product data");

    const token = Cookies.get("auth_token");
    if (!token) throw new Error("Unauthorized: No token found");
    console.log(product.photos);
    // Prepare FormData
    const formData = new FormData();
    formData.append("name", product.name);
    formData.append("description", product.description);
    formData.append("price", product.price);
    formData.append("category", product.category);
    formData.append("Quentity", product.Quentity);
    formData.append("shipping", product.shipping);
    formData.append("sold", product.sold);

    // Append the photo if available
    if (product.photo) {
      formData.append("photo", product.photo);
    }

    // Append multiple images
    if (product.photos && product.photos.length > 0) {
      product.photos.forEach((file) => {
        formData.append("photos", file); // Append each file
      });
    }

    // Send request with FormData
    const response = await api.post(`/product/creat/${userId}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data", // Required for FormData
      },
    });

    return response.data;
  } catch (error) {
    console.error(
      "Error adding product:",
      error.response?.data || error.message
    );
    return null;
  }
};

export { addProduct };
