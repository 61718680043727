import * as yup from "yup";

const signupSchema = yup.object().shape({
  username: yup.string().required("please enter your username"),

  password: yup
    .string()
    .required("please enter your password")
    .min(6, "password must be at least 6 characters"),

  // phone_state_code: yup.string().required("please enter phone state code"),
  phone: yup.string().min(10).required("please enter your phone number"),
  address: yup.string().min(3).optional(),
  // .matches(/[a-z]/, "يجب أن تحتوي كلمة المرور على حرف صغير")
  // .matches(/[A-Z]/, "يجب أن تحتوي كلمة المرور على حرف كبير")
  // .matches(/[0-9]/, "يجب أن تحتوي كلمة المرور على رقم")
  // .matches(/[@$!%*?&#]/, "يجب أن تحتوي كلمة المرور على رمز خاص"),
});

const loginValidation = yup.object().shape({
  phone: yup.string().min(10).required("يرجى إدخال رقم الهاتف"),
  password: yup
    .string()
    .min(6, "يجب أن تكون كلمة المرور 6 أحرف على الأقل")
    .required("يرجى إدخال كلمة المرور"),
});
const phoneValidation = yup.object().shape({
  phone: yup.string().min(10).required("يرجى إدخال رقم الهاتف"),
});

const passwordValidation = yup.object().shape({
  password: yup
    .string()
    .required("كلمة المرور مطلوبة")
    .min(6, "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل")
    .matches(/[a-z]/, "يجب أن تحتوي كلمة المرور على حرف صغير")
    .matches(/[A-Z]/, "يجب أن تحتوي كلمة المرور على حرف كبير")
    .matches(/[0-9]/, "يجب أن تحتوي كلمة المرور على رقم")
    .matches(/[@$!%*?&#]/, "يجب أن تحتوي كلمة المرور على رمز خاص"),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref("password")],
      "يجب أن تتطابق كلمة المرور مع تأكيد كلمة المرور"
    )
    .required("تأكيد كلمة المرور مطلوب"),
});

const resetPasswordValidation = yup.object().shape({
  newPassword: yup
    .string()
    .required("new password field is required!")
    .min(6, "password should be 6 characters at least"),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "password is not match"),
});

export {
  signupSchema,
  loginValidation,
  passwordValidation,
  resetPasswordValidation,
  phoneValidation,
};
