import React from "react";
// import { Redirect, Link } from "react-router-dom";
import Layout from "./../core/Layout";
// import { isAuthenticate } from "./auth";
// import { API } from "./../config";
import Login from "../utils/login/Login";
const Signin = () => {
  // const [values, setValues] = useState({
  //   myUser: "",
  //   password: "",
  //   error: "",
  //   history: "",
  //   loading: false,
  //   redirectToReferrer: false,
  // });
  // const { myUser, password, error, loading, redirectToReferrer } = values;
  // const { user } = isAuthenticate();
  /******************************************************************************************************************************* */
  // const creatUi = () => {
  //   return (
  //     <form>
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           gap: "2rem",
  //         }}
  //       >
  //         <div
  //           className="form-group"
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             gap: "0.5rem",
  //           }}
  //         >
  //           <label className="text-muted">Phone Number</label>
  //           <input
  //             type="text"
  //             className="form-control"
  //             style={{
  //               fontSize: "24px",
  //             }}
  //             onChange={handelChanges("myUser")}
  //           ></input>
  //         </div>
  //         <div
  //           className="form-group"
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             gap: "0.5rem",
  //           }}
  //         >
  //           <label className="text-muted">Password</label>
  //           <input
  //             type="password"
  //             className="form-control"
  //             style={{
  //               fontSize: "24px",
  //             }}
  //             onChange={handelChanges("password")}
  //           ></input>
  //         </div>
  //       </div>
  //       <p>
  //         If you don't have a account please <Link to="/signup">sign up</Link>
  //       </p>
  //       <button className="btn btn-primary" onClick={submitHandler}>
  //         Sign in
  //       </button>
  //     </form>
  //   );
  // };
  /************************************************************************************************************************************ */
  // const handelChanges = (name) => (event) => {
  //   setValues({
  //     ...values,
  //     loading: false,
  //     redirectToReferrer: false,
  //     [name]: event.target.value,
  //   });
  // };
  /************************************************************************************************************************************** */
  // const signinSubmit = (user) => {
  //   return fetch(`${API}/signin`, {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(user),
  //   })
  //     .then((Response) => {
  //       return Response.json();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       return error;
  //     });
  // };
  /********************************************************************************************************************************************** */
  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   signinSubmit({ myUser, password })
  //     //this part to get the error msg and clear the text field
  //     .then((data) => {
  //       if (data.error) {
  //         setValues({
  //           ...values,
  //           error: data.error,
  //           loading: false,
  //           redirectToReferrer: false,
  //         });
  //       } else {
  //         authentication(data, () =>
  //           setValues({
  //             ...values,
  //             myUser: "",
  //             password: "",
  //             error: false,
  //             loading: true,
  //             redirectToReferrer: true,
  //           })
  //         );
  //       }
  //     });
  // };
  /********************************************************************************************************************************************* */
  // const showErrorMsg = () => {
  //   return (
  //     <div
  //       className="alert alert-danger"
  //       style={{ display: error ? "" : "none" }}
  //     >
  //       {error}
  //     </div>
  //   );
  // };
  // const showSuccessMsg = () => {
  //   return (
  //     <div
  //       className="alert alert-info"
  //       style={{ display: loading ? "" : "none" }}
  //     >
  //       Loading......
  //     </div>
  //   );
  // };
  /**************************************************************************************************************************************************** */
  // const redirectUser = () => {
  //   if (redirectToReferrer) {
  //     if (user !== null && user?.role === 0)
  //       return <Redirect to="/user/dashboard" />;
  //     else return <Redirect to="/admin/dashboard" />;
  //   }
  //   if (isAuthenticate()) {
  //     return <Redirect to="/"></Redirect>;
  //   }
  // };

  /***************************************************************************************************************************************************** */

  return (
    <Layout
      title="WELCOME TO ZAGSYSTEMS"
      discreption="Sign in to your account "
      className="max-w-full lg:max-w-[70%] mx-auto"
    >
      <Login />
    </Layout>
  );
};

export default Signin;
