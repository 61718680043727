import Cookies from "js-cookie";
import api from "./utils/api";
const createOrder = async (userId, order) => {
  try {
    console.log(userId);
    console.log(order);
    const token = Cookies.get("auth_token");
    const data = await api.post(
      `/order/create/${userId}`,
      {
        order: order,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    console.log(err);
    throw err.response.data.error;
  }
};

export { createOrder };
