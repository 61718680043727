import React from "react";
import Menu from "./Menu";
import "./style.css";
import img from "./logo.png";
// import { Link } from "react-router-dom";
const Layout = ({
  title = "Title",
  // discreption = "Description",
  children,
  className,
}) => {
  return (
    <div className="flex items-center justify-between min-h-screen flex-col min-w-screen">
      <div className="w-full">
        <Menu></Menu>
        <div className="w-full jumbotron shadow-none p-3 mb-5 bg-light">
          <h1
            style={{ textAlign: "center" }}
            className="text-center  title-md-extra-large mt-2 mb-2 tracking-wider sm:text-3xl font-semibold"
          >
            {title}
          </h1>
          {/* <p className="lead  mt-2 mb-2" style={{ textAlign: "center" }}>
          {" "}
          {discreption}
        </p> */}
        </div>
      </div>
      <div className={`${className}`}>{children}</div>
      <footer
        className="w-full opacity-1"
        style={{
          backgroundColor: "#212529",
          marginTop: "50px",
          //   position: "absolute",
          //   bottom: "0",
          //   width: "100%",
        }}
      >
        <div className="container">
          <div className="row align-items-center pb-2">
            <div className="col-md-4 text-center text-md-left mt-2">
              <img
                src={img}
                alt="ZagSystems"
                className="footer-logo opacity-5"
                width="170px"
                height="40px"
              />
            </div>
            <div className="col-md-4">
              <ul className="footer-icon-social mb-0 mt-4 p-0 text-center text-md-right title-xs-small title-medium">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/zagsystems"
                    target="_blank"
                    className="text-gray-dark"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="white"
                      className="bi bi-facebook"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                    </svg>
                  </a>
                </li>
                <li className="list-inline-item pl-2">
                  <a
                    href="https://www.twitter.com/zagsystems"
                    target="_blank"
                    className="text-gray-dark"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="white"
                      className="bi bi-twitter"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                    </svg>
                  </a>
                </li>
                <li className="list-inline-item pl-4">
                  <a
                    href="https://www.linkedin.com/company/zagsystems"
                    target="_blank"
                    className="text-gray-dark"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="white"
                      className="bi bi-linkedin"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
                    </svg>
                  </a>
                </li>
                <li className="list-inline-item pl-4">
                  <a
                    href="https://www.youtube.com/channel/UCApZwHMYwzuk53znVdytCQg"
                    target="_blank"
                    className="text-gray-dark"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="white"
                      className="bi bi-youtube"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li>
                <li className="list-inline-item pl-4">
                  <a
                    href="https://www.github.com/zagsystems"
                    target="_blank"
                    className="text-gray-dark"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="white"
                      className="bi bi-github"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row align-items-center border-top pt-3">
            <div className="col text-center">
              <span className="font-alt font-w-600 letter-spacing-1 text-white text-small text-uppercase">
                © <span id="year">2024</span> ZagSystems. All rights reserved.
              </span>
              <div>
                <a
                  href="https://zagsystems.org/z-store-privacy-policy"
                  className="text-white text-small"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Check our Z-Store Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Layout;
