import { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

// Create Auth Context
const AuthContext = createContext();

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  console.log("user in useUser", user);

  // Check login state on mount (e.g., from cookies)
  useEffect(() => {
    const token = Cookies.get("auth_token");
    if (token) {
      setIsLoggedIn(true);
      setUser(JSON.parse(localStorage.getItem("userData")) || {});
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom Hook for Using Auth
export const useAuth = () => {
  return useContext(AuthContext);
};
