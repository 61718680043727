import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import SignOut from "../user/Signout";
import { isAuthenticate } from "../user/auth";
import { Fragment, useEffect } from "react";
import { itemTotal } from "./CartHelper";
import Search_nav from "./Search_nav";
import "react-dropdown/style.css";
import img from "./logo.png";
import Navbar from "react-bootstrap/Navbar";
import { Col, Row, NavDropdown, Container, Nav } from "react-bootstrap";
import { useCart } from "../utils/CartContext";
import { useAuth } from "../utils/AuthContext";
import useLogout from "../utils/logout/useLogout";
const isActive = (history, path) => {
  return history.location.pathname === path
    ? { color: "#ff9900" }
    : { color: "#FFFF" };
};

const Menu = ({ history }) => {
  const { totalItems } = useCart();
  const { isLoggedIn, user } = useAuth();
  const { logoutUserFn } = useLogout();

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container fluid>
        <Row
          lg="6"
          xl="9"
          md="auto"
          xxl="8"
          xs="auto"
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Col lg="3" xl="3" xxl="3" style={{ height: "55px" }}>
            <Navbar.Brand>
              <Link className="nav-link" to="/">
                <img
                  src={img}
                  alt="ZagSystems"
                  width="210"
                  height="50"
                  style={{ objectFit: "cotain" }}
                />
              </Link>
            </Navbar.Brand>
          </Col>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="flex items-center flex-col lg:flex-row"
          >
            <Col lg="7" xl="8" xxl="8">
              <Nav className="">
                <Search_nav></Search_nav>
              </Nav>
            </Col>
            <Col
              lg={{ size: 2, offset: 1 }}
              xl={{ size: 2, offset: 1 }}
              xxl={{ size: 2, offset: 1 }}
              style={{ marginLeft: "20px" }}
              className="flex items-start w-full"
            >
              <Nav className="flex flex-col justify-start items-start">
                {!isLoggedIn && (
                  <div style={{ display: "inline-block" }} className="">
                    <Nav.Link
                      href="/signin"
                      active={true}
                      className="flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="white"
                        className="bi bi-person "
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                      </svg>
                      &nbsp; Sign in
                    </Nav.Link>
                  </div>
                )}
                {isLoggedIn && user && (
                  <NavDropdown
                    className="flex"
                    title={
                      <div className="flex items-center dropdownToggle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="white"
                          className="bi bi-person "
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                        </svg>
                        &nbsp;
                        {user?.name || "user"}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="m8 10.207l3.854-3.853l-.707-.708L8 8.793L4.854 5.646l-.708.708z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    }
                    active={true}
                    id="collasible-nav-dropdown"
                  >
                    {isLoggedIn && user && (
                      <Fragment>
                        {user?.role === 1 && (
                          <NavDropdown.Item>
                            <Link
                              to="/admin/dashboard"
                              style={{
                                textDecoration: "none ",
                                color: "black",
                              }}
                              className="flex items-center gap-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-person-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                <path
                                  fillRule="evenodd"
                                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                                />
                              </svg>
                              &nbsp;Profile
                            </Link>
                          </NavDropdown.Item>
                        )}
                        {user.role === 0 && (
                          <NavDropdown.Item>
                            <Link
                              to="/user/dashboard"
                              style={{
                                textDecoration: "none ",
                                color: "black",
                              }}
                              className="flex items-center"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-person-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                <path
                                  fillRule="evenodd"
                                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                                />
                              </svg>
                              &nbsp;Profile
                            </Link>
                          </NavDropdown.Item>
                        )}
                        <NavDropdown.Divider />
                        <NavDropdown.Item className="">
                          <span
                            className="flex items-center gap-2"
                            onClick={logoutUserFn}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-door-open-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
                            </svg>
                            &nbsp;Sign Out
                          </span>
                        </NavDropdown.Item>
                      </Fragment>
                    )}
                  </NavDropdown>
                )}
                <Nav.Link
                  href="/cart"
                  style={isActive(history, "/cart")}
                  className="relative flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    // className="bi bi-cart-plus "
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z" />
                  </svg>
                  &nbsp;
                  <sup className="cart-badge absolute top-2 right-1 text-white rounded-full h-3 w-3 text-sm p-0 flex items-center justify-center">
                    <small className="">{totalItems}</small>
                  </sup>
                </Nav.Link>
              </Nav>
            </Col>
          </Navbar.Collapse>
        </Row>
      </Container>
    </Navbar>
  );
};
export default withRouter(Menu);
