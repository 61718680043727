import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast";

// Initialize the context
const CartContext = createContext();

// Cart reducer function to handle state updates
const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM": {
      // console.log(action.payload);
      const { item, count = 1 } = action.payload;

      const existingItemIndex = state.items.findIndex(
        (existingItem) => existingItem?._id === item._id
      );

      if (existingItemIndex > -1) {
        // Item exists, update count
        const updatedItems = [...state.items];
        updatedItems[existingItemIndex] = {
          ...updatedItems[existingItemIndex],
          count: updatedItems[existingItemIndex].count + count,
        };

        return {
          ...state,
          items: updatedItems,
          totalItems: state.totalItems + count,
        };
      } else {
        // New item, add to cart with specified count
        return {
          ...state,
          items: [...state.items, { ...item, count }],
          totalItems: state.totalItems + count,
        };
      }
    }

    case "REMOVE_ITEM": {
      const filteredItems = state.items.filter(
        (item) => item._id !== action.payload
      );
      const removedItem = state.items.find(
        (item) => item._id === action.payload
      );
      const countRemoved = removedItem ? removedItem.count : 0;

      return {
        ...state,
        items: filteredItems,
        totalItems: state.totalItems - countRemoved,
      };
    }

    case "DECREASE_COUNT": {
      const existingItemIndex = state.items.findIndex(
        (item) => item._id === action.payload
      );

      if (existingItemIndex === -1) return state;

      const item = state.items[existingItemIndex];

      // If count is 1, remove the item
      if (item.count === 1) {
        return {
          ...state,
          items: state.items.filter((item) => item._id !== action.payload),
          totalItems: state.totalItems - 1,
        };
      }

      // Decrease count by 1
      const updatedItems = [...state.items];
      updatedItems[existingItemIndex] = {
        ...updatedItems[existingItemIndex],
        count: updatedItems[existingItemIndex].count - 1,
      };

      return {
        ...state,
        items: updatedItems,
        totalItems: state.totalItems - 1,
      };
    }

    case "CLEAR_CART":
      return {
        items: [],
        totalItems: 0,
      };

    case "UPDATE_Count": {
      const { id, count } = action.payload;
      const existingItemIndex = state.items.findIndex(
        (item) => item._id === id
      );

      if (existingItemIndex === -1) return state;

      const currentCount = state.items[existingItemIndex].count;
      const countDifference = count - currentCount;

      const updatedItems = [...state.items];
      updatedItems[existingItemIndex] = {
        ...updatedItems[existingItemIndex],
        count,
      };

      return {
        ...state,
        items: updatedItems,
        totalItems: state.totalItems + countDifference,
      };
    }

    case "INITIALIZE_CART": {
      return action.payload;
    }

    default:
      return state;
  }
};

// Initial cart state
const initialState = {
  items: [],
  totalItems: 0,
};

// Provider component
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const [isInitialized, setIsInitialized] = useState(false);
  // Load cart from localStorage on initial render
  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      try {
        const parsedCart = JSON.parse(savedCart);
        // Replace the entire state with the saved cart
        dispatch({ type: "INITIALIZE_CART", payload: parsedCart });
      } catch (error) {
        console.log("Failed to parse saved cart:", error);
      }
    }
    setIsInitialized(true);
  }, []);

  // Save cart to localStorage whenever it changes
  useEffect(() => {
    if (isInitialized) localStorage.setItem("cart", JSON.stringify(state));
  }, [state, isInitialized]);

  // Calculate total price
  const totalPrice = state.items.reduce(
    (total, item) => total + item.price * item.count,
    0
  );

  // Exposed cart functionality
  const cart = {
    items: state.items,
    totalItems: state.totalItems,
    totalPrice,

    addItem: (item, count = 1) => {
      dispatch({
        type: "ADD_ITEM",
        payload: {
          item,
          count: parseInt(count, 10) || 1,
        },
      });

      // Show toast notification
      if (!cart.isInCart(item._id)) {
        toast.success(`Added ${item.name} to cart`);
      } else {
        toast(`Item ${item.name} updated by ${count}`, {
          style: { borderBottom: "4px solid blue" },
        });
      }
    },

    removeItem: (itemId) => {
      const item = state.items.find((item) => item._id === itemId);
      const itemName = item ? item.name : "Item";
      dispatch({ type: "REMOVE_ITEM", payload: itemId });
      toast.error(`Removed ${itemName} from cart`);
    },

    decreaseCount: (itemId) => {
      const item = state.items.find((item) => item._id === itemId);

      dispatch({ type: "DECREASE_COUNT", payload: itemId });
      if (item && item.count === 1) {
        toast.error(`Removed ${item.name} from cart`);
      }
      if (item && item.count > 1) {
        toast(`Item ${item.name} has been decreased by 1`, {
          style: { borderBottom: "4px solid blue" },
        });
      }
    },

    updateCount: (itemId, count) => {
      const item = state.items.find((item) => item._id === itemId);

      dispatch({
        type: "UPDATE_Count",
        payload: { id: itemId, count: parseInt(count, 10) || 0 },
      });
      toast(`${item.name} has been updated`);
    },

    clearCart: () => {
      dispatch({ type: "CLEAR_CART" });
      toast.error("Cart has been cleared");
    },

    isInCart: (itemId) => {
      return state.items.some((item) => item._id === itemId);
    },

    getItemCount: (itemId) => {
      const item = state.items.find((item) => item._id === itemId);
      return item ? item.count : 0;
    },
  };

  return <CartContext.Provider value={cart}>{children}</CartContext.Provider>;
};

// Custom hook to use the cart context
export const useCart = () => {
  const context = useContext(CartContext);

  if (context === undefined) {
    throw new Error("useCart must be used within a CartProvider");
  }

  return context;
};
