import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import ShowImage from "./ShowImage";
import Moment from "react-moment";
import { addItem, itemTotal, updateItem, removeItem } from "./CartHelper";
import { useCart } from "../utils/CartContext";

const Card = ({
  product,
  viewProductButton = true,
  viewAddToCart = true,
  cartUpdate = false,
  showRemoveProductButton = false,
  location = "/",
  setRun = (f) => f,
  run = undefined,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [count, setCount] = useState(product.count);
  const { addItem } = useCart();

  const handleAddToCart = (count = 1) => {
    addItem(product);
  };

  const handleChange = (productId) => (event) => {
    setRun(!run); // run useEffect in parent Cart
    setCount(event.target.value < 1 ? 1 : event.target.value);
    if (event.target.value >= 1) {
      updateItem(productId, event.target.value);
    }
  };
  const showProductButton = () => {
    return (
      <Link to={`/product/${product._id}`} className="pr-3">
        {viewProductButton && (
          <button
            className="btn btn-outline-dark flex items-center gap-3"
            style={{ marginRight: "10px" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-collection-fill"
              viewBox="0 0 16 16"
            >
              <path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z" />
            </svg>
            &nbsp;View Product
          </button>
        )}
      </Link>
    );
  };
  /********************************************************** */
  const showAddtoCartButton = () => {
    return (
      viewAddToCart &&
      product.Quentity > 0 && (
        <button className="btn btn-outline-dark" onClick={handleAddToCart}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-cart-plus mb-1 "
            viewBox="0 0 16 16"
          >
            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z" />
          </svg>
          {/* &nbsp; Add to cart */}
        </button>
      )
    );
  };
  const showRemoveButton = (showRemoveProductButton) => {
    return (
      showRemoveProductButton && (
        <button
          onClick={() => {
            removeItem(product._id);
            setRun(!run); // run useEffect in parent Cart
          }}
          className="btn btn-outline-danger "
        >
          Remove Product
        </button>
      )
    );
  };
  const showStock = (quantity) => {
    return (
      product.Quentity === 0 && (
        <span
          className="outOfStock"
          style={{ fontSize: "18px", backgroundColor: "#ff0000" }}
        >
          Coming Soon{" "}
        </span>
      )
    );
  };
  const onClickHandler = () => {
    addItem(product, () => {
      setRedirect(true);
    });
  };
  const RedirectTo = () => {
    if (redirect) return <Redirect to={location}></Redirect>;
  };
  const showCartUpdateOptions = (cartUpdate) => {
    return (
      cartUpdate && (
        <div>
          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text">Adjust Quantity</span>
            </div>
            <input
              type="number"
              className="form-control"
              value={count}
              onChange={handleChange(product._id)}
            />
          </div>
        </div>
      )
    );
  };
  const showDescription = () => {
    if (viewProductButton) {
      return (
        <p className="black-10" style={{ textAlign: "left", height: "150px" }}>
          {product.description ? product.description.substring(0, 200) : ""}{" "}
          .......{" "}
        </p>
      );
    } else {
      return (
        <p className="black-10">
          {product.description ? product.description : ""}{" "}
        </p>
      );
    }
  };
  return (
    <div
      className="rounded-3 border border-dark overflow-hidden card"
      style={{
        position: "relative",
      }}
    >
      <Link
        to={`/product/${product._id}`}
        style={{
          textDecoration: "none",
          display: "block",
          backgroundColor: "#212529",
          color: "black",
          padding: "1rem 0.5rem",
        }}
      >
        <div
          className="name text-center w-full"
          style={{ whiteSpace: "nowrap", fontSize: "1.5rem" }}
        >
          {product.name}
        </div>
      </Link>
      <div className="">
        <Link
          to={`/product/${product._id}`}
          className="h-fit p-0 md:pt-8 m-0"
          style={{
            textDecoration: "none ",
            color: "black",
            backgroundColor: "#f7f7f7",
            display: "block",
            overflow: "hidden",
          }}
        >
          <ShowImage item={product} url="products" />
          <button className="view">Quick View</button>
        </Link>
        <div style={{ padding: "1rem 2rem" }}>
          <div style={{ textAlign: "center" }}>
            {showStock(product.Quentity)}
          </div>
          {/* <hr></hr> */}
          {/* {showDescription()} */}
          <p
            style={{
              backgroundColor: "green",
              width: "fit-content",
              padding: "0.25rem 0.75rem",
              color: "white",
              borderRadius: "12px",
              cursor: "pointer",
              margin: "0 auto 1rem",
              textAlign: "center",
            }}
          >
            {product.category && product.category.name}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontWeight: "800",
                marginBottom: 0,
                fontSize: "1.25rem",
              }}
            >
              {product.price} EGP
            </p>
            {showAddtoCartButton()}
          </div>
          {/* <p>
            Added on :{" "}
            <Moment format="Do MMMM  YYYY">{product.createdAt}</Moment>
          </p> */}
          <div style={{ textAlign: "center" }} className="mt-2 mb-2">
            {/* {showProductButton()} */}
            {showRemoveButton(showRemoveProductButton)}
            {showCartUpdateOptions(cartUpdate)}
          </div>
          {RedirectTo()}
        </div>
      </div>
    </div>
  );
};
export default Card;
