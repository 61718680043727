import React, { useEffect } from "react";
import Routes from "./Router";
import "./App.css";
import { Toaster } from "react-hot-toast";
import { CartProvider } from "./utils/CartContext";
function App() {
  return (
    <>
      <Toaster
        position="bottom-left"
        toastOptions={{
          success: {
            duration: 2000,
            style: {
              background: "#fff",
              color: "#000",
              borderBottom: "4px solid yellow",
            },
          },
          error: {
            duration: 2000,
            style: {
              background: "#fff",
              color: "#000",
              borderBottom: "4px solid red",
            },
          },
          custom: {
            duration: 2000,
            style: {
              background: "#fff",
              color: "#000",
              borderBottom: "4px solid blue",
            },
          },
        }}
      />
      <CartProvider>
        <Routes> </Routes>
      </CartProvider>
    </>
  );
}

export default App;
