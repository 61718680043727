import React from "react";
import { API } from "./../config";
const ShowImage = ({ item, url, imgWidth, imgHieght }) => {
  return (
    <div className="product-img">
      <img
        src={`${API}/${url}/photo/${item._id}`}
        alt={item.name}
        className="img-fluid mb-3 md:h-60"
        style={{
          width: "100%" /* width of container */,
          objectFit: "contain",
          overflow: "hidden",
          backgroundColor: "#f7f7f7",
          padding: "0",
        }}
      />
    </div>
  );
};
export default ShowImage;
