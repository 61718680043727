import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { isAuthenticate } from "../user/auth";
import { listOrders, getStatusValues, updateOrderStatus } from "./apiAdmin";
import moment from "moment";
import { listOrdersByUser } from "./apiAdmin";
import AdminLinks from "./../user/AdminLinks";
import { useHistory } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";
import Cookies from "js-cookie";

const TestOrders = () => {
  const [orders, setOrders] = useState([]);
  const [statusValues, setStatusValues] = useState([]);
  //   const { user, token } = isAuthenticate();
  const [myUser, setMyUser] = useState("");
  const [error, setError] = useState("");
  const [searched, setSearched] = useState(false);
  const [data, setData] = useState([]);
  const history = useHistory();
  const { user, isLoggedIn } = useAuth();
  const token = Cookies.get("auth_token");

  const viewProductHandler = (e, orderId, Product) => {
    e.preventDefault();
    history.push({
      pathname: `/admin/orders/${orderId}`,
      state: { products: Product },
    });
  };
  const loadOrders = () => {
    listOrders(user._id, token).then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setOrders(data);
      }
    });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    searchData();
  };
  const searchData = () => {
    listOrdersByUser(user._id, token, myUser).then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setSearched(true);
        setData(data);
        setError("");
      }
    });
  };
  const onHandelChange = (event) => {
    if (myUser.length <= 1) {
      setSearched(false);
      setError("");
    }
    setMyUser(event.target.value);
  };
  const SearchForm = () => (
    <form onSubmit={onSubmitHandler}>
      <span className="input-group-text bg-light " style={{ border: "none" }}>
        <div className="input-group input-group-sm">
          <input
            type="search"
            className="form-control"
            onChange={onHandelChange}
            placeholder="Search by Email/Phone number"
            size="120"
          />
        </div>
        <div
          className="btn input-group-append"
          style={{ border: "none", paddingLeft: "0px", paddingRight: "12px" }}
        >
          <button className="input-group-text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </button>
        </div>
      </span>
    </form>
  );
  const showErrorMsg = () => {
    return (
      <div
        className="alert alert-danger"
        style={{ display: error ? "" : "none" }}
      >
        {error}
      </div>
    );
  };
  const loadStatusValues = () => {
    getStatusValues(user._id, token).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        setStatusValues(data);
      }
    });
  };

  useEffect(() => {
    loadOrders();
    loadStatusValues();
  }, [searched]);
  console.log(orders);

  const showOrdersLength = () => {
    if (!searched) {
      if (orders.length > 0) {
        return (
          <h1 className="text-danger display-2">
            Total orders: {orders.length}
          </h1>
        );
      } else {
        return <h1 className="text-danger">No orders</h1>;
      }
    } else {
      if (data.length > 0) {
        return (
          <h1 className="text-danger display-2">Total orders: {data.length}</h1>
        );
      } else {
        return <h1 className="text-danger">No orders</h1>;
      }
    }
  };

  const showInput = (key, value) => (
    <div className="input-group mb-2 mr-sm-2">
      <div className="input-group-prepend">
        <div className="input-group-text">{key}</div>
      </div>
      <input type="text" value={value} className="form-control" readOnly />
    </div>
  );

  const handleStatusChange = (e, orderId) => {
    updateOrderStatus(user._id, token, orderId, e.target.value).then((data) => {
      if (data.error) {
        console.log("Status update failed");
      } else {
        loadOrders();
      }
    });
  };

  const showStatus = (o) => (
    <div className="form-group">
      <h3 className="mark mb-4">Status: {o.status}</h3>
      <select
        className="form-control"
        onChange={(e) => handleStatusChange(e, o._id)}
      >
        <option>Update Status</option>
        {statusValues.map((status, index) => (
          <option key={index} value={status}>
            {status}
          </option>
        ))}
      </select>
    </div>
  );
  return (
    <Layout
      title="Orders"
      discreption={`${user?.name}, you can manage all the orders here`}
      className="container-fluid"
    >
      <div className="row">
        <div className="col-12 col-lg-2 ">
          <AdminLinks></AdminLinks>
        </div>
        <div className="col-12 col-lg-10">
          {showOrdersLength()}
          {showErrorMsg()}
          <div>{SearchForm()}</div>
          <div className="table-responsive">
            <table className="table ">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Order ID</th>
                  <th scope="col">Status</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Ordered by</th>
                  <th scope="col">Date</th>
                  <th scope="col">Address</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>
                {!searched &&
                  orders.map((o, oIndex) => {
                    return (
                      <tr key={oIndex}>
                        <th scope="row">{oIndex}</th>
                        <td>{o._id}</td>
                        <td>
                          <select
                            className="form-select form-select-sm"
                            aria-label="form-select-sm example"
                            onChange={(e) => handleStatusChange(e, o._id)}
                            value={!o.status ? "" : o.status}
                          >
                            {statusValues.map((status, index) => (
                              <option key={index} value={status}>
                                {status}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>{o.amount} EGP</td>
                        <td>
                          <p>{o.user !== null && o.user.name}</p>
                          {o.user.phoneNo ? (
                            <p className="text-sm">{o.user.phoneNo}</p>
                          ) : (
                            <p className="text-sm">
                              {o.user.email ? o.user.email : ""}
                            </p>
                          )}
                        </td>
                        <td>
                          {moment(o.createdAt).format("Do MMMM  YYYY, hh:mm a")}
                        </td>
                        <td>{o.address}</td>
                        <td>
                          <button
                            className="btn btn-secondary"
                            onClick={(e) =>
                              viewProductHandler(e, o._id, o.products)
                            }
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                {searched &&
                  data.map((o, oIndex) => {
                    return (
                      <tr key={oIndex}>
                        <th scope="row">{oIndex}</th>
                        <td>{o._id}</td>
                        <td>
                          <select
                            className="form-select form-select-sm"
                            aria-label="form-select-sm example"
                            onChange={(e) => handleStatusChange(e, o._id)}
                            value={!o.status ? "" : o.status}
                          >
                            {statusValues.map((status, index) => (
                              <option key={index} value={status}>
                                {status}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>{o.amount} EGP</td>
                        <td>{o.user !== null && o.user.name}</td>
                        <td>
                          {moment(o.createdAt).format("Do MMMM  YYYY, hh:mm a")}
                        </td>
                        <td>{o.address}</td>
                        <td>
                          <button
                            className="btn btn-secondary"
                            onClick={(e) =>
                              viewProductHandler(e, o._id, o.products)
                            }
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TestOrders;
