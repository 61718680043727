import { initializeApp } from "firebase/app";

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCSYmmVCmQ6WkdkQj2T2EtjFPuPgLc2quk",
  authDomain: "z-store-a82cc.firebaseapp.com",
  projectId: "z-store-a82cc",
  storageBucket: "z-store-a82cc.firebasestorage.app",
  messagingSenderId: "526320647623",
  appId: "1:526320647623:web:aabd9cbffe9d51fe252de2",
  // measurementId: "G-VSKH7PQH5Q",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, RecaptchaVerifier, signInWithPhoneNumber };
