import axios from "axios";
import { API } from "../../config";
// import { CONSUMED_BE_URL } from "../../config/config";

const api = axios.create({
  baseURL: API,
  // timeout: 2000,
  headers: {
    Accept: "*/*",
    // "Accept-Encoding": "deflate, gzip",
    "Accept-Language": "en", // Added Accept-Language
    // "User-Agent":
    //     "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/131.0.0.0 Safari/537.36",
    "Content-Type": "application/json",
  },
});

export default api;
