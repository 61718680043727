export function compressImage(
  file,
  maxSizeKB = 20,
  maxWidth = 420,
  quality = 0.7
) {
  return new Promise((resolve, reject) => {
    // If file is already smaller than maxSizeKB, resolve immediately
    if (file.size / 1024 <= maxSizeKB) {
      resolve(file);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set new dimensions
        const scaleFactor = Math.min(1, maxWidth / img.width);
        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const compress = () => {
          canvas.toBlob(
            (blob) => {
              if (blob.size / 1024 <= maxSizeKB) {
                resolve(new File([blob], file.name, { type: "image/jpeg" }));
              } else if (quality > 0.1) {
                quality -= 0.1;
                compress();
              } else {
                // If can't compress further, return the last blob
                resolve(new File([blob], file.name, { type: "image/jpeg" }));
              }
            },
            "image/jpeg",
            quality
          );
        };
        compress();
      };
    };
    reader.onerror = (error) => reject(error);
  });
}
