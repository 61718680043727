const addItem = (item, next, count = 1) => {
  let cart = [];
  if (typeof window !== "undefined") {
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }
    cart.push({
      ...item,
      count,
    });
    // cart = Array.from(new Set(cart.map((p) => p._id))).map((id) => {
    //   return cart.find((p) => p._id === id);
    // });
    localStorage.setItem("cart", JSON.stringify(cart));
    next();
  }
};
const itemTotal = () => {
  if (typeof window !== "undefined") {
    if (JSON.parse(localStorage.getItem("cart")) === null) {
      return 0;
    }
    let count = 0;
    JSON.parse(localStorage.getItem("cart")).map((item) => {
      count += item.count;
    });
    return count;
  }

  return 0;
};
const getItems = () => {
  if (typeof window !== "undefined") {
    return JSON.parse(localStorage.getItem("cart"));
  }
};
const updateItem = (productId, count) => {
  let cart = [];
  if (typeof window !== "undefined") {
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }
    cart.map((p, i) => {
      if (p._id === productId) {
        cart[i].count = count;
      }
    });
    localStorage.setItem("cart", JSON.stringify(cart));
  }
};
const removeItem = (productId) => {
  let cart = [];
  if (typeof window !== "undefined") {
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }
    cart.map((p, i) => {
      if (p._id === productId) {
        cart.splice(i, 1);
      }
    });
    localStorage.setItem("cart", JSON.stringify(cart));
  }
  return cart;
};
const emptyCart = (next) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("cart");
    next();
  }
};

export { addItem };
export { getItems };
export { itemTotal };
export { updateItem };
export { removeItem };
export { emptyCart };
