import Layout from "./Layout";
import { useState, useEffect } from "react";
import { getItems } from "./CartHelper";
import { Link } from "react-router-dom";
import CheckOut from "./CheckOut";
import AdminLinks from "./../user/AdminLinks";
import UserLinks from "./../user/UserLinks";
import { useCart } from "../utils/CartContext";
import { API } from "../config";
import { useAuth } from "../utils/AuthContext";
const Cart = () => {
  const [items, setItems] = useState([{}]);
  const [run, setRun] = useState(false);

  const { loggedIn, user } = useAuth();
  const {
    items: cartItems,
    totalItems,
    addItem,
    decreaseCount,
    removeItem,
    // clearCart,
  } = useCart();

  useEffect(() => {
    setItems(getItems());
  }, [run]);
  // const showItems = (items) => {
  //   return (
  //     <div>
  //       {/* <h2>Your cart has {`${items.length}`} items</h2> */}
  //       {/* <hr /> */}
  //       {/* {items.map((product, i) => (
  //                   <Card
  //                       key={i}
  //                       product={product}
  //                       viewAddToCart = {false}
  //                       cartUpdate={true}
  //                       showRemoveProductButton ={true}
  //                       setRun={setRun}
  //                       run = {run}

  //                   />
  //               ))} */}
  //     </div>
  //   );
  // };
  // const noItemsMessage = () => (
  //   <h2>
  //     Your cart is empty. <br />{" "}
  //     <Link
  //       to={{
  //         pathname: "/shop",
  //         state: { products: [], redirected: true },
  //       }}
  //     >
  //       Continue shopping
  //     </Link>
  //   </h2>
  // );

  return (
    <Layout
      title="Shopping Cart"
      discreption="Manage your cart items."
      className="container-fluid"
    >
      <div className="cart flex flex-col sm:flex-row items-center md:items-start justify-center md:justify-between flex-wrap gap-4">
        {/* USER OR ADMIN LINKS */}
        <div
          className="userAdminLinks"
          style={{
            flex: 1 / 6,
          }}
        >
          <div className="col-12 col-lg-3 ">
            {loggedIn && user?.role !== 0 ? (
              <AdminLinks></AdminLinks>
            ) : (
              <UserLinks></UserLinks>
            )}
          </div>
        </div>
        {cartItems.length === 0 ? (
          <div
            className="cart-empty flex flex-col items-center sm:items-end justify-center"
            style={{
              flex: 5 / 6,
            }}
          >
            <p
              style={{
                fontSize: "1.75rem",
              }}
            >
              Your cart is empty
            </p>
            <Link
              to="/"
              style={{
                backgroundColor: "#da0e1a",
                textDecoration: "none",
                padding: "0.25rem 0.5rem",
                color: "#fff",
                borderRadius: "12px",
              }}
            >
              View Products
            </Link>
          </div>
        ) : (
          <>
            {/* LAYOUT OF CART */}

            <div className="cart-items-container " style={{}}>
              <h3 className="text-2xl">Your Cart has {totalItems} items</h3>

              <div className="cart-items ">
                {cartItems.map((cartItem, i) => (
                  <div
                    key={i}
                    className="cart-item items-start justify-between hover:bg-slate-200 p-2 border-b-2 border-b-black"
                  >
                    <div className="flex flex-col md:flex-row gap-4">
                      <div className="flex items-center">
                        <div className="item-image">
                          {cartItem._id ? (
                            <img
                              // src={`${API}/${url}/photo/${item._id}`}

                              src={`${API}/products/photo/${cartItem._id}`}
                              alt={cartItem.name}
                            />
                          ) : (
                            <div className="no-image">No Image</div>
                          )}
                        </div>

                        <div className="item-details">
                          <h3>{cartItem.name}</h3>
                          <p className="item-price">
                            {cartItem.price.toFixed(2)} EGP
                          </p>
                        </div>
                      </div>
                      <div className="flex w-full justify-between">
                        <div className="item-quantity">
                          <button
                            className="quantity-btn"
                            onClick={() => decreaseCount(cartItem._id)}
                          >
                            -
                          </button>
                          <span>{cartItem.count}</span>
                          <button
                            className="quantity-btn"
                            onClick={() => addItem(cartItem)}
                          >
                            +
                          </button>
                        </div>

                        <div className="item-total w-1/4">
                          {(cartItem.price * cartItem.count).toFixed(2)} EGP
                        </div>
                      </div>
                    </div>

                    <button
                      className="remove-btn text-2xl cursor-pointer"
                      onClick={() => removeItem(cartItem._id)}
                    >
                      ✕
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12 col-lg-3 cart-summary">
              <h2 className="mb-4">Your cart summary</h2>
              <hr />
              <CheckOut products={items} setRun={setRun} run={run} />
              <Link to="/" className="shopping-btn">
                Continue Shopping{" "}
              </Link>
              {/* <button className="clear-cart-btn" onClick={clearCart}>
                Clear Cart
              </button> */}
            </div>
          </>
        )}
      </div>
      {/* <div className="row">
        <div className="col-12 col-lg-3 ">
          {isAuthenticate() && isAuthenticate().user.role !== 0 ? (
            <AdminLinks></AdminLinks>
          ) : (
            <UserLinks></UserLinks>
          )}
        </div>
        <div className="col-12 col-lg-6">
          {items !== null && items.length > 0
            ? showItems(items)
            : noItemsMessage()}
        </div>
        <div className="col-12 col-lg-3">
          <h2 className="mb-4">Your cart summary</h2>
          <hr />
          <CheckOut products={items} setRun={setRun} run={run} />
        </div>
      </div> */}
      <br></br>
      <br></br>
      <br></br>
    </Layout>
  );
};
export default Cart;
