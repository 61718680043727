import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isAuthenticate } from "../user/auth";
import { useAuth } from "../utils/AuthContext";
function AdminRoute({ component: Component, authed, ...rest }) {
  const { isLoggedIn, user } = useAuth();
  console.log("user in admin route: ", user);
  console.log("logged in status: ", isLoggedIn);
  return (
    isLoggedIn && (
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn && user && user?.role === 1 ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        }
      />
    )
  );
}
export default AdminRoute;
