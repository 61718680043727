import Layout from "./Layout";
import { useState, useEffect, useRef } from "react";
import { listRelatedProducts, read } from "./apiCore";
import Card from "./Card";
import { API } from "../config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useCart } from "../utils/CartContext";
import { useAuth } from "../utils/AuthContext";
const Product = (props) => {
  const [product, setProduct] = useState({});
  const [listedProducts, setListedProducts] = useState([{}]);
  const [error, setError] = useState(false);
  const [activeImage, setActiveImage] = useState(0);
  const [addToCartQuantity, setAddToCartQuantity] = useState(1);
  const { isLoggedIn } = useAuth();
  const { addItem } = useCart();

  const history = useHistory();

  const buyNow = () => {
    if (!isLoggedIn) {
      history.push("/signin");
    } else {
      addItem(product, addToCartQuantity);
      history.push("/cart");
    }
  };

  const zoomWrapperRef = useRef(null);
  const zoomImgRef = useRef(null);

  const imageZoom = (e) => {
    if (!zoomWrapperRef.current || !zoomImgRef.current) return;

    const { left, top, width, height } =
      zoomWrapperRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;

    zoomImgRef.current.style.transformOrigin = `${x}% ${y}%`;
  };

  const loadSingleProduct = (productId) => {
    read(productId).then((data) => {
      if (data.error) {
        setError(data.error);
        console.log(error);
      } else {
        setProduct(data);
      }
    });
    listRelatedProducts(productId).then((data) => {
      if (data.error) {
        setError(data.error);
        console.log(error);
      } else {
        setListedProducts(data);
      }
    });
  };
  useEffect(() => {
    const ProductId = props.match.params.productId;

    loadSingleProduct(ProductId);
  }, [props.match.params.productId]);
  const relatedProductsUi = () => {
    return (
      <div className="col-12  col-lg-4">
        <h2>Related Products</h2>
        {listedProducts.map((P, i) => (
          <div key={i} className="mr-3 mb-3">
            {/* <Card  product = {P}></Card> */}
          </div>
        ))}
      </div>
    );
  };
  const singleProductsUi = () => {
    return (
      <div className=" col-12 col-lg-8">
        <Card
          product={product}
          viewProductButton={false}
          location={`${props.location.pathname}`}
        ></Card>
      </div>
    );
  };

  const onClickHandler = () => {
    addItem(product, addToCartQuantity);
    setAddToCartQuantity(1);
  };

  console.log(product);

  return (
    <Layout title={product?.name} discreption=" " className="container-fluid">
      <div className="">
        <p className="mx-auto w-full flex items-center mb-6">
          Category:{" "}
          <span
            style={{
              backgroundColor: "green",
              color: "#fff",
              borderRadius: "12px",
              padding: "0.5rem 0.5rem",
              marginLeft: "1rem",
              cursor: "pointer",
            }}
          >
            {product?.category?.name}
          </span>
        </p>
        <div className="productItem">
          <div className="productItemImages">
            <div
              className="zoom-wrapper"
              ref={zoomWrapperRef}
              onMouseMove={imageZoom}
            >
              <img
                ref={zoomImgRef}
                src={`${API}/products/photo/${
                  activeImage === 0 && product._id
                }`}
                alt={product.name}
                className="zoom-img"
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              {product?.photos?.map((photo) => (
                <img
                  src={`${API + "/products/photo/" + photo}`}
                  alt="itemImage-2"
                  style={{
                    border: "1px solid #000",
                    width: "100px",
                    objectFit: "contain",
                    marginTop: "1rem",
                    cursor: "pointer",
                    borderRadius: "6px",
                  }}
                />
              ))}
            </div>
          </div>
          {/* CONTENT OF PRODUCT */}
          <div className="productItemContent flex flex-col gap-2">
            <h2 className="text-2xl font-bold">{product?.name}</h2>
            <h3 className="text-2xl font-bold text-green-700">
              {product?.price} EGP
            </h3>

            <p className="text-xl">{product?.description}</p>
            {/* <p>Shipping: {product?.shipping ? "Available" : "Not Available"}</p> */}
            <p
              className="text-2xl"
              style={{
                fontWeight: "500",
                color:
                  product?.Quentity === 0
                    ? "#ff0000" // Red for "Out of Stock"
                    : product?.Quentity < 5
                    ? "#f59e0b" // Orange for "Only a few left!"
                    : "#22c55e", // Green for "In Stock"
              }}
            >
              {product?.Quentity === 0
                ? "Coming soon"
                : product?.Quentity < 5
                ? `Only ${product?.Quentity} left!`
                : "In Stock"}
            </p>

            {product.Quentity !== 0 && (
              <div
                className="ctaCartContainer"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3rem",
                  marginTop: "0.75rem",
                }}
              >
                <div
                  className="addToCardButtons"
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={() =>
                      setAddToCartQuantity((prev) => (prev < 2 ? 1 : prev - 1))
                    }
                    style={{
                      padding: "0.25rem 0.75rem",
                      cursor: "pointer",
                      fontSize: "1.75rem",
                      backgroundColor: "transparent",
                    }}
                  >
                    -
                  </button>
                  <label
                    style={{
                      fontSize: "1.5rem",
                    }}
                  >
                    {addToCartQuantity}
                  </label>
                  <button
                    onClick={() =>
                      setAddToCartQuantity((prev) => (prev > 5 ? 6 : prev + 1))
                    }
                    style={{
                      padding: "0.25rem 0.75rem",
                      cursor: "pointer",
                      fontSize: "1.75rem",
                      backgroundColor: "transparent",
                    }}
                  >
                    +
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <button
                    onClick={() => onClickHandler(product)}
                    style={{
                      backgroundColor: "#DA0E1A",
                      borderRadius: "12px",
                      color: "#fff",
                      padding: "0.25rem 1rem",
                      border: "none",
                      outline: "none",
                      fontSize: "1.25rem",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    Add To Cart
                  </button>
                  <button
                    onClick={buyNow}
                    style={{
                      backgroundColor: "#DA0E1A",
                      borderRadius: "12px",
                      color: "#fff",
                      padding: "0.25rem 1rem",
                      border: "none",
                      outline: "none",
                      fontSize: "1.25rem",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    Buy now
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Product;
