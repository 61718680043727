import { useState } from "react";
import { loginUser } from "../../services/auth";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../utils/AuthContext";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

function useLogin() {
  const [isLogging, setIsLogging] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useHistory();
  const { setIsLoggedIn, setUser } = useAuth();

  const loginUserFn = async ({ phone, password }) => {
    setIsLogging(true);
    setError(null);
    try {
      const data = await loginUser(phone, password); // Assuming this is an API call
      setIsLoggedIn(true);
      console.log("data in useLogin", data);
      setUser(data?.user);
      Cookies.set("auth_token", data?.token, {
        expires: 7,
        secure: true,
        sameSite: "Strict",
      });
      localStorage.setItem("userData", JSON.stringify(data?.user));
      console.log(data.user.role);
      if (data.user && data?.user?.role === 1) {
        navigate.push("/admin/dashboard");
      }
      if (data.user && data.user.role !== 1) {
        navigate.push("/");
      }
      toast.success(
        "Successfully logged in, you can log in to the mobile app using these credentials"
      );
    } catch (err) {
      // setError(err instanceof Error ? err.message : "Internal server error");
      toast.error(JSON.parse(err.request.response).error);
    } finally {
      setIsLogging(false);
    }
  };

  return {
    loginUserFn,
    isLogging,
    isErrorLogging: error,
  };
}

export default useLogin;
