const data = [
  {
    id: 2,
    name: "username",
    type: "text",
  },
  {
    id: 8,
    name: "phone",
  },
  {
    id: 10,
    name: "password",
    type: "password",
  },

  {
    id: 4,
    name: "address",
    type: "text",
  },
];

const readableLabels = {
  password: "Password",
  username: "Username",
  phone: "Phone Number",
  address: "Address",
};

export { data, readableLabels };
