import { Controller } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import { readableLabels } from "./data";
import { useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PhoneInputComponent = ({ field, control, register, errors }) => {
  const [showPassword, setShowPassword] = useState(false);

  const isPhone = field?.name === "phone";

  return (
    <div className={`mb-4 w-full`}>
      <label
        htmlFor={field?.name}
        className="cursor-pointer z-10 text-[#da0e1a] text-xl mb-2 font-normal"
      >
        {readableLabels[field?.name]}
      </label>
      {isPhone ? (
        <Controller
          name={field?.name}
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <PhoneInput
              value={value}
              onChange={(val) => onChange(val)}
              defaultCountry="eg"
              charAfterDialCode=""
              className={`bg-transparent text-2xl text-blue-600 border-2 border-[#0000006a] rounded-[10px] h-12 flex items-center justify-center px-4 w-full outline-none`}
              inputClassName="w-full h-full "
              inputStyle={{
                background: "#fff",
                border: "2px solid #0000006a",
                border: "none",
                color: "#000",
                fontSize: "1rem",
                height: "100%",
              }}
              countrySelectorStyleProps={{
                buttonStyle: {
                  background: "transparent",
                  border: "none",
                  borderRight: "2px solid #0000006a",
                  margin: "0 auto",
                  borderRadius: "10px 0 0 10px",
                  padding: "1.30rem",
                  color: "white",
                  width: "4rem",
                },
              }}
            />
          )}
        />
      ) : (
        <div className={`${field?.type === "password" ? "relative" : ""}`}>
          <input
            {...register(field?.name)}
            id={field.name}
            type={
              field.type === "password" && showPassword ? "text" : field.type
            }
            placeholder={field.name === "website_url" ? "example.com" : ""}
            className={`bg-transparent border-2 border-[#0000006a] rounded-[10px] py-3 px-4 w-full outline-none `}
          />
          <button
            onClick={() => setShowPassword(!showPassword)}
            className="absolute top-1/2 -translate-y-1/2 right-4"
          >
            {showPassword && field.type === "password" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={32}
                height={32}
                viewBox="0 0 16 16"
              >
                <path
                  fill="currentColor"
                  d="m10.12 10.827l4.026 4.027a.5.5 0 0 0 .708-.708l-13-13a.5.5 0 1 0-.708.708l3.23 3.23A6 6 0 0 0 3.2 6.182a6.7 6.7 0 0 0-1.117 1.982c-.021.061-.047.145-.047.145l-.018.062s-.076.497.355.611a.5.5 0 0 0 .611-.355l.001-.003l.008-.025l.035-.109a5.7 5.7 0 0 1 .945-1.674a5 5 0 0 1 1.124-1.014L6.675 7.38a2.5 2.5 0 1 0 3.446 3.446m-.74-.74A1.5 1.5 0 1 1 7.413 8.12zM6.32 4.2l.854.854Q7.564 5 8 5c2.044 0 3.286.912 4.028 1.817a5.7 5.7 0 0 1 .945 1.674q.025.073.035.109l.008.025v.003l.001.001a.5.5 0 0 0 .966-.257v-.003l-.001-.004l-.004-.013a2 2 0 0 0-.06-.187a6.7 6.7 0 0 0-1.117-1.982C11.905 5.089 10.396 4 8.002 4c-.618 0-1.177.072-1.681.199"
                />
              </svg>
            ) : !showPassword && field.type === "password" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={32}
                height={32}
                viewBox="0 0 16 16"
              >
                <path
                  fill="currentColor"
                  d="M8 6.003a2.667 2.667 0 1 1 0 5.334a2.667 2.667 0 0 1 0-5.334m0 1a1.667 1.667 0 1 0 0 3.334a1.667 1.667 0 0 0 0-3.334m0-3.336c3.076 0 5.73 2.1 6.467 5.043a.5.5 0 1 1-.97.242a5.67 5.67 0 0 0-10.995.004a.5.5 0 0 1-.97-.243A6.67 6.67 0 0 1 8 3.667"
                />
              </svg>
            ) : null}
          </button>
        </div>
      )}
      {errors[field.name] && (
        <p className="text-red-500 text-xs mt-1 text-left">
          {errors[field.name]?.message}
        </p>
      )}
    </div>
  );
};

export default PhoneInputComponent;
