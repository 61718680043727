import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Signin from "./user/Signin";
import Home from "./pages/Home";
import Shop from "./core/Shop";
import PrivateRoute from "./auth/PrivateRoute";
import AdminRoute from "./auth/AdminRoute";
import AddCategory from "./admin/AddCategory";
import AddProduct from "./admin/AddProduct";
import Product from "./core/Product";
import Cart from "./core/Cart";
// import Orders from "./admin/Orders";
import ManageProduct from "./admin/ManageProduct";
import UpdateProduct from "./admin/UpdateProduct";
import Profile from "./user/Profile";
import Dashboard from "./user/userDashBoard";
// import { Nav } from "react-bootstrap";
import NotFound from "./core/NotFound";
import TestOrders from "./admin/TestOrders";
import ProductOrders from "./admin/ProductOrders";
import OrderUser from "./user/OrderUser";
import VerifyOtp from "./pages/VerifyOtp";
import Signup from "./pages/Signup";
import AdminDashBoard from "./user/AdminDashBoard";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/signup" exact component={Signup} />
        <Route path="/verify-otp" exact component={VerifyOtp} />

        <Route path="/signin" exact component={Signin} />
        <Route path="/" exact component={Home} />
        <Route path="/shop" component={Shop} />
        <PrivateRoute path="/user/dashboard" exact component={Dashboard} />
        <PrivateRoute
          path="/user/dashboard/orders/:orderId"
          exact
          component={OrderUser}
        />
        <AdminRoute path="/admin/dashboard" exact component={AdminDashBoard} />
        <AdminRoute path="/create/category" exact component={AddCategory} />
        <AdminRoute path="/create/product" exact component={AddProduct} />
        <AdminRoute path="/admin/orders" exact component={TestOrders} />
        <AdminRoute
          path="/admin/orders/:orderId"
          exact
          component={ProductOrders}
        />
        <Route path="/product/:productId" exact component={Product} />
        <AdminRoute path="/manage/products" exact component={ManageProduct} />
        <AdminRoute
          path="/admin/product/update/:productId"
          exact
          component={UpdateProduct}
        />
        <Route path="/cart" exact component={Cart} />
        <PrivateRoute path="/profile/update" exact component={Profile} />
        <Route path="" exact component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
export default Routes;
