import { useForm } from "react-hook-form";
// import useSignup from "../../auth/useSignup";
import { yupResolver } from "@hookform/resolvers/yup";
import { signupSchema } from "./validation";
import { useRef, useState } from "react";
import "react-international-phone/style.css";
import { data } from "./data";
import PhoneInputComponent from "./PhoneInput";
import useOtp from "./useOtp";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { createUser } from "../../services/auth";

function Form() {
  const recaptchaRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signupSchema),
    mode: "onBlur",
  });

  const { sendOtpFn, isSendingOtp } = useOtp();

  const onSubmit = (formData) => {
    console.log(formData);
    setLoading(true);
    sendOtpFn({ data: formData, mobile: formData.phone });
  };

  return (
    <div
      className={`bg-transparent rounded-[10px] py-5 px-10 z-[4] lg:w-[70%] mx-auto `}
    >
      {/* <input type="text" ref={recaptchaRef} /> */}

      <form
        className="flex flex-wrap flex-col md:flex-row gap-x-2 gap-y-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        {data?.map((field) => (
          <PhoneInputComponent
            // ref={field.id === 8 ? recaptchaRef : ""}
            key={field.id}
            field={field}
            control={control}
            register={register}
            errors={errors}
          />
        ))}{" "}
        <button
          id="sign-up-button"
          className="bg-[#da0e1a] rounded-lg w-full py-2 md:py-4 font-medium text-sm md:text-xl cursor-pointer transition-colors hover:bg-[#c40d17] mb-4 text-white"
        >
          {loading ? "loading..." : "Submit"}
          {/* {isSendingOtp ? "loading..." : "Submit"} */}
        </button>
      </form>
      <div className="flex items-center justify-center gap-3 text-xl">
        <p>Already have an account</p>
        <Link to="/signin" className="underline hover:text-[#ae0b15]">
          Login
        </Link>
      </div>
      <div id="recaptcha-container" ref={recaptchaRef}></div>
    </div>
  );
}

export default Form;
