import { useRef, useState } from "react";
// import useOtp from "../features/auth/useOtp";
import { useLocation } from "react-router-dom";
import useOtp from "../utils/register/useOtp";
// import useReset from "./auth/useReset";

function useSearchParams() {
  return new URLSearchParams(useLocation().search);
}

function VerifyOtp() {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const inputRefs = useRef([]);
  const searchParams = useSearchParams();
  const passwordMode = searchParams.get("mode") === "password";

  const { verifyOtpFn, isVerifying, verifyOtpPassFn, isVerifyOtpPass } =
    useOtp();

  const handleChange = (value, index) => {
    // Update the OTP array
    const newOtp = [...otp];
    newOtp[index] = value.slice(0, 1); // Ensure only one character is added
    setOtp(newOtp);

    // Focus the next input field
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newOtp.join("").length === 6) {
      console.log("last otp", newOtp.join(""));
      if (passwordMode) {
        verifyOtpPassFn(newOtp.join(""));
      } else {
        verifyOtpFn();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      // Focus the previous input field
      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center min-h-[calc(100vh-7rem)] gap-8 md:gap-12">
        <h3 className="font-bold text-2xl md:text-6xl">Verify</h3>
        <p className="md:text-2xl text-center px-4">
          Code was sent to you via number, please check and verify
        </p>
        <div className="flex items-center gap-2 md:gap-4">
          {Array.from({ length: 6 }).map((_, i) => (
            <input
              key={i}
              ref={(el) => (inputRefs.current[i] = el)}
              type="text"
              maxLength={1}
              value={otp[i]}
              onChange={(e) => handleChange(e.target.value, i)}
              onKeyDown={(e) => handleKeyDown(e, i)}
              className="rounded-lg h-8 w-8 sm:h-12 sm:w-12 md:w-20 md:h-20 md:text-4xl text-[#da0e1a] text-center font-semibold border-2 border-[#da0e1a]"
            />
          ))}
        </div>
        {(isVerifying || isVerifyOtpPass) && <p>Loading...</p>}
      </div>
      <div id="recaptcha-container"></div>
    </div>
  );
}

export default VerifyOtp;
