import React, { useState } from "react";
import Layout from "../core/Layout";
import { isAuthenticate } from "../user/auth";
import { AddNewCategory } from "./apiAdmin";
import { Link } from "react-router-dom";
import AdminLinks from "./../user/AdminLinks";
import { useAuth } from "../utils/AuthContext";
const AddCategory = () => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const { user, token } = isAuthenticate();
  const { isLoggedIn } = useAuth();
  const creatUi = () => {
    return (
      <form onSubmit={onClickHandler}>
        <div className="form-group">
          <label className="text-muted">Name</label>
          <br></br>
          <input
            type="text"
            className="form-control"
            autoFocus
            value={name}
            onChange={onChangeHandler}
            required
          />
          <br></br>
          <button className="btn btn-outline-primary">Submit</button>
        </div>
      </form>
    );
  };
  const onChangeHandler = (e) => {
    setError("");
    setSuccess(false);
    setName(e.target.value);
  };

  const onClickHandler = (e) => {
    e.preventDefault();
    AddNewCategory(user._id, token, { name }).then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setError("");
        setSuccess(true);
      }
    });
  };

  const showSuccess = () => {
    if (success) {
      return <h3 className="text-success">Category is created </h3>;
    }
  };
  const showError = () => {
    if (error) {
      return <h3 className="text-danger">This Category is exit</h3>;
    }
  };
  const goBack = () => {
    if (success) {
      return (
        <div className="mt-5">
          <Link to="/admin/dashboard" className="text-warning">
            Back to Dashboard
          </Link>
        </div>
      );
    }
  };
  return (
    <Layout
      title="Create Category"
      discreption="Are you ready to add new  category ?"
      className="container-fluid"
    >
      {showSuccess()}
      {showError()}
      <div className="row">
        {isLoggedIn && (
          <div className="col-5 col-lg-3 ">
            <AdminLinks></AdminLinks>
          </div>
        )}
        <div className="col-7">{creatUi()}</div>
      </div>
      {goBack()}
    </Layout>
  );
};
export default AddCategory;
