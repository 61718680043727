import React from "react";
// import { NavLink } from "react-router-dom";
import Layout from "../core/Layout";
// import { isAuthenticate } from "./auth";
// import { Link } from "react-router-dom";
import AdminLinks from "./AdminLinks";
import { useAuth } from "../utils/AuthContext";
const AdminDashBoard = () => {
  const { user } = useAuth();

  //   const {
  //     user: { _id, name, email, role },
  //   } = isAuthenticate();
  const adminInfo = () => {
    return (
      <div className="card mb-5">
        <h3 className="card-header">Admin Info</h3>
        <ul className="list-group">
          <li className="list-group-item">{user.name}</li>
          <li className="list-group-item">{user.email}</li>
          <li className="list-group-item">
            {user.role === 1 ? "Admin" : "User"}
          </li>
        </ul>
      </div>
    );
  };
  return (
    <Layout
      title={`Welcome, ${user.name}`}
      discreption=" "
      className="container-fluid"
    >
      <div className="row">
        <div className="col-5 col-lg-3 ">
          <AdminLinks></AdminLinks>
        </div>
        <div className="col-7 col-lg-8">{adminInfo()}</div>
      </div>
    </Layout>
  );
};
export default AdminDashBoard;
