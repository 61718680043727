import Layout from "./../core/Layout";
import { useState } from "react";
import { useEffect } from "react";
import { getproducts, deletProduct } from "./apiAdmin";
import { isAuthenticate } from "../user/auth";
import { Link } from "react-router-dom";
import AdminLinks from "./../user/AdminLinks";
const ManageProduct = () => {
  const [products, setProducts] = useState([{}]);
  const [error, setError] = useState(false);
  const { user, token } = isAuthenticate();
  const loadProducts = () => {
    getproducts().then((data) => {
      if (data.error) {
        console.log(data.error);
        setError(data.error);
      } else {
        setProducts(data);
      }
    });
  };
  const destroy = (productId) => {
    deletProduct(user._id, token, productId).then((data) => {
      if (data.error) {
        console.log(data.error);
      } else {
        loadProducts();
      }
    });
  };
  useEffect(() => {
    loadProducts();
  }, []);
  return (
    <Layout
      title="Manage Products"
      discreption="Are you ready to update /  delete products ?"
      className="container-fluid"
    >
      <div className="row">
        <div className="col-12 col-lg-3 ">
          <AdminLinks></AdminLinks>
        </div>
        <div className="col-12 col-lg-9 ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Product name</th>
                  <th scope="col">Update</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {products.map((p, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{i}</th>
                      <td>
                        <strong>{p.name}</strong>
                      </td>
                      <td>
                        <Link to={`/admin/product/update/${p._id}`}>
                          <span className="btn btn-outline-success btn-sm align-middle">
                            Update
                          </span>
                        </Link>
                      </td>
                      <td>
                        <span
                          onClick={() => destroy(p._id)}
                          className="btn btn-outline-danger btn-sm"
                        >
                          Delete
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ManageProduct;
