import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";
import { useAuth } from "../AuthContext";
function useLogout() {
  const navigate = useHistory();
  const { setIsLoggedIn, setUser } = useAuth();

  const logoutUserFn = async () => {
    setIsLoggedIn(false);
    localStorage.clear();
    Cookies.remove("auth_token");
    setUser([]);
    navigate.push("/");
  };

  return {
    logoutUserFn,
  };
}

export default useLogout;
