import React, { useState } from "react";
// import { getProduct, createOrder } from "./apiCore";
// import { emptyCart } from "./CartHelper";
import { isAuthenticate } from "../user/auth";
import { Link } from "react-router-dom";
import { useCart } from "../utils/CartContext";
import { useAuth } from "../utils/AuthContext";
import useOrder from "../utils/order/useOrder";

const Checkout = ({ products, setRun = (f) => f, run = undefined }) => {
  const [data, setData] = useState({
    loading: false,
    success: false,
    clientToken: null,
    error: "",
    instance: {},
    address: "",
  });
  const { totalItems, items, totalPrice, clearCart } = useCart();
  const { createOrderFn } = useOrder();
  // const userId = isAuthenticate() && isAuthenticate().user._id;
  const userAddress = isAuthenticate() && isAuthenticate().user.address;
  // const token = isAuthenticate() && isAuthenticate().token;
  const handleAddress = (event) => {
    setData({ ...data, address: event.target.value });
  };
  // const getTotal = () => {
  //     return products!== null && products.reduce((currentValue, nextValue) => {
  //         return currentValue + nextValue.count * nextValue.price;
  //     }, 0);
  // };
  const { isLoggedIn } = useAuth();
  const showCheckout = () => {
    return isLoggedIn ? (
      <div>{showDropIn()}</div>
    ) : (
      <Link to="/signin">
        <button className="btn btn-primary">Sign in to checkout</button>
      </Link>
    );
  };

  const buy = () => {
    const orderData = {
      amount: totalPrice,
      address: data?.address,
      products: items,
    };
    createOrderFn(orderData);
    clearCart();
    // setData({ loading: true });
    // const paymentData = +totalPrice;
    // empty cart
    // create order
    // const createOrderData = {
    //   products: products,
    //   amount: paymentData,
    //   address: data.address === "" ? userAddress : data.address,
    // };
    // createOrder(userId, token, createOrderData);
    //   .then((response) => {
    //     emptyCart(() => {
    //       setRun(!run); // run useEffect in parent Cart
    //       console.log("payment success and empty cart");
    //       setData({
    //         loading: false,
    //         success: true,
    //         reload: true,
    //       });
    //     });
    //   })
    //   .catch((error) => {
    //     setData({ loading: false });
    //   });
  };

  const showDropIn = () => {
    // console.log(products);

    return (
      <div onBlur={() => setData({ ...data, error: "" })}>
        {totalItems && totalItems > 0 ? (
          <div>
            <div className="gorm-group mb-3">
              <label className="text-muted">Delivery address:</label>
              <textarea
                onChange={handleAddress}
                className="form-control"
                value={data.address}
                placeholder={userAddress}
              />
            </div>
            <button onClick={buy} className="btn btn-success btn-block">
              Cash on Delivery
            </button>
          </div>
        ) : null}
      </div>
    );
  };

  const showError = (error) => (
    <div
      className="alert alert-danger"
      style={{ display: error ? "" : "none" }}
    >
      {error}
    </div>
  );

  const showSuccess = (success) => (
    <div
      className="alert alert-info"
      style={{ display: success ? "" : "none" }}
    >
      Thanks! Your Order was created successfully!
    </div>
  );

  const showLoading = (loading) =>
    loading && <h2 className="text-danger">Loading...</h2>;

  return (
    <div>
      <h2>Total: {totalPrice} EGP</h2>
      {showLoading(data.loading)}
      {showSuccess(data.success)}
      {showError(data.error)}
      {showCheckout()}
    </div>
  );
};

export default Checkout;
