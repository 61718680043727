import React from "react";
import Layout from "../core/Layout";
import { getProduct } from "../core/apiCore";
import { useState, useEffect } from "react";
import Card from "../core/Card";
import { API } from "../config";
import img from "./assets/logo.png";
import BeatLoader from "react-spinners/BeatLoader";
const Home = () => {
  const [productBySell, setproductBySell] = useState([]);
  const [productByArrival, setproductByArrival] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadProductBySell = () => {
    return getProduct("sold").then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setproductBySell(data);
      }
    });
  };
  const loadProductByArrival = () => {
    return getProduct("createdAt").then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setproductByArrival(data);
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    loadProductBySell();
    loadProductByArrival();
  }, []);
  console.log(productByArrival);

  return (
    <div>
      {loading ? (
        <div className="splash-screen col">
          <div
            className="row-12 "
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <img
              src={img}
              alt="ZagSystems"
              width="400"
              height="100"
              style={{ marginTop: "40vh" }}
            />
          </div>
          <div
            className="row-12 "
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <BeatLoader loading={loading} margin={10} size={30} />
          </div>
        </div>
      ) : (
        <Layout
          title="WELCOME TO ZAGSYSTEMS"
          discreption="IOT, EMBEDED SYSTEMS AND SOFTWARE SOLUTIONS "
          className="container-fluid"
        >
          {/* <h2 className="">Our Products</h2> */}
          <div className="cardsUpContainer">
            <div className="cardsContainer mb-0">
              {productByArrival &&
                productByArrival?.map((product, i) => (
                  <div key={i}>
                    <Card product={product} />
                  </div>
                ))}
            </div>
          </div>
          {/* <div
            style={{
              display: "block",
              gap: "1rem",
              flexWrap: "wrap",
              // justifyContent: "center",
              width: "fit-content",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {productByArrival &&
                productByArrival?.map((product, i) => {
                  return (
                    <div key={i} className="">
                      <Card product={product} />
                    </div>
                  );
                })}
            </div>
          </div> */}
          <br></br>
          {/* <h2>Best Sellers</h2>
          <div className="row">
            {productBySell.map((product, i) => {
              return (
                <div key={i} className="">
                  <Card product={product} />
                </div>
              );
            })}
          </div> */}
        </Layout>
      )}
    </div>
  );
};
export default Home;
