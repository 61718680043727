import React from "react";
import Layout from "../core/Layout";

import Form from "../utils/register/Form";
const Signup = () => {
  return (
    <Layout
      title="Sign up Page"
      discreption="This is the sign up page for Ecommerce website "
      className="max-w-full lg:max-w-[70%] mx-auto"
    >
      <Form />
    </Layout>
  );
};

export default Signup;
