import toast from "react-hot-toast";
import api from "./utils/api";
import { verifyOTP } from "./verifyOtp";
import { sendOTP } from "./sendOtp";

// { name, address, email, password, phoneNo }
const createUser = async (dataToBE) => {
  try {
    const data = await api.post("/signup", {
      name: dataToBE.username,
      address: dataToBE.address,
      phoneNo: dataToBE.phone,
      password: dataToBE.password,
    });
    console.log(data);
    // return data;
  } catch (err) {
    toast.error(JSON.parse(err.request.response).error);
  }
};

const loginUser = async (phone, password) => {
  try {
    const data = await api.post("/signin", {
      myUser: phone,
      password,
    });
    return data.data;
  } catch (err) {
    console.log(err);
    throw err;
    // toast.error(err.message);
  }
};

const sendOtpSignup = async (data, mobile) => {
  // const otp = generateOTP();
  // sessionStorage.setItem("otp", otp);
  // console.log("data from service: ", data);
  // console.log(mobile);

  try {
    // console.log("mobile", mobile);
    sendOTP(mobile);
    // await axios.post("https://smsmisr.com/api/OTP/?username=", {

    //   username: "bfbc8183-2efd-4c25-8dad-527c182f1e82",
    //   password:
    //     "633a5afc3107c97ac3becdbbd259fff4acd1cdd57c92e31a00fceb847b47c6e5",
    //   sender:
    //     "b611afb996655a94c8e942a823f1421de42bf8335d24ba1f84c437b2ab11ca27",
    //   environment: "2", // 1 for live, 2 for tes,
    //   templateToken:
    //     "0f9217c9d760c1c0ed47b8afb5425708da7d98729016a8accfc14f9cc8d1ba83",
    //   otp: otp,
    //   mobile: mobile,
    // });

    return data;
  } catch (err) {
    console.log(err);
  }
};

const verifyOtpPass = async (otp) => {
  verifyOTP(otp, "password");
};

const logout = async () => {
  const data = await api.get("/signout");
  return data;
};
export { createUser, loginUser, sendOtpSignup, verifyOtpPass };

// const sinupSubmit = (user) => {
//     console.log("hello");
//     console.log("============");
//     console.log(`${API}`);

//     return fetch(`${API}/signup`, {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(user),
//     })
//       .then((Response) => {
//         return Response.json();
//       })
//       .catch((error) => {
//         return error;
//       });
//   };
